import { writable } from 'svelte/store';
window.__env = window.__env || {};
export default function EnvService() {
    const { subscribe, update } = writable({
        apiHost: 'http://localhost:5100',
        apiUrl: 'api/',
        authConfig: {
            clientId: '18ff6c2b-c314-4044-8f48-c2056f4b0333',
            authority: 'https://login.microsoftonline.com/3db1148c-0f88-4558-aa86-fb66345eae7b',
            redirectUri: 'http://localhost:8080',
            postLogoutRedirectUri: 'http://localhost:8080/'
        }
    });
    const environmentVariables = window.__env || {};
    for (const key in environmentVariables) {
        if (environmentVariables.hasOwnProperty(key)) {
            update(env => (Object.assign(Object.assign({}, env), { [key]: environmentVariables[key] })));
        }
    }
    return {
        subscribe
    };
}
