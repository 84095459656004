<script lang="ts">
  import {signIn} from '../auth'
  import {isAuthenticated} from '../stores/stores'
</script>

<div class="login-container">
    <div class="login-wrapper">
        <div>
            <!--            <img class="brand-logo" src="/assets/layout/images/logo_brand.png" alt="">-->
            <span class="brand-name">process.env.APP_NAME</span>
        </div>
        {#if $isAuthenticated === false}
            <button type="button" on:click={signIn} class="microsoft-button">
                <img alt="microsoft logo" src="images/microsoft_logo.svg"/>
                <span>Mit Microsoft anmelden</span>
            </button>
            <span>
                <a href="https://www.wentzel-dr.de/impressum">Impressum</a> | <a href="https://www.wentzel-dr.de/datenschutz">Datenschutz</a>
            </span>
        {/if}
    </div>
</div>

<style>
    .login-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .brand-name {
        font-size: min(10vw, 100px);
        font-weight: 800;
        line-height: min(10vw, 100px);
        letter-spacing: -0.06em;
        color: #565656;
    }

    .microsoft-button {
        height: 41px;
        min-width: 210px;
        background-color: white;
        border: 1px solid #8C8C8C;
        padding: 0 12px;
        display: flex;
        align-items: center;
    }

    .microsoft-button img {
        padding-right: 12px;
    }

    .microsoft-button span {
        line-height: 41px;
        font-family: "Segoe UI Regular", sans-serif;
        font-size: 15px;
        font-weight: 600;
        color: #5E5E5E;
    }

    .microsoft-button:hover {
        cursor: pointer;
        background-color: #ededed;
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    }

    .login-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5vh;
        padding: min(5vw, 50px);
    }
</style>
