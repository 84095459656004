<script lang="ts">
    import type { Flaeche } from '../../types';

    import FlaechenTableRow from './FlaechenTableRow.svelte';

    export let flaechen: Flaeche[];

</script>

<tbody>
{#each flaechen as flaeche}
    <FlaechenTableRow {flaeche}/>
{/each}
</tbody>
