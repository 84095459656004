<script lang="ts">
  import {fetchAngebote} from '../../stores/stores'
  import type {Angebot, Auftrag, Gesuch} from '../../types'
  import {euroIntFormatter} from '../../types'
  import {
    CheckCircleIcon,
    ClockIcon,
    MapIcon,
    PaperclipIcon,
    ThumbsDownIcon,
    ThumbsUpIcon,
    XCircleIcon
  } from 'svelte-feather-icons'
  import {onMount} from 'svelte'
  import {getMailPreview, putAngebotMailStatus} from '../../stores/auftrag-stores'

  export let auftrag: Auftrag
  export let gesuch: Gesuch
  export let edited: boolean

  let angebote: Angebot[] = []

  let isQueryInProgress = false

  function toggleMailStatus(angebot: Angebot): void {
    if (isQueryInProgress) {
      return
    }
    const newStatus = angebot.status === 'DO_SEND' ? 'WITHHOLD' : 'DO_SEND'
    isQueryInProgress = true
    putAngebotMailStatus(gesuch.id, angebot.hash!, newStatus)
        .then(newAngebot => {
          angebot.status = newAngebot.status
          angebote = [...angebote]
          isQueryInProgress = false
        })
  }

  onMount(() => {
    if (auftrag && auftrag.id) {
      fetchAngebote(auftrag.id, true).then(a => angebote = a)
    }
  })

  async function openMailPreview(): Promise<void> {
    window.open(
        await getMailPreview(gesuch.id),
        '_blank',
    )
  }

</script>


<div class="modal-body">

    <small class="text-muted">Änderungen zum Mailversand werden sofort bereits vor Speicherung übernommen.</small>
    <div class="table-wrapper">
        <table class="table table-striped table-hover small mt-3">
            <tr>
                <th class="btn-col"></th>
                <th>Status</th>
                <th>Code</th>
                <th>Ort</th>
                <th>Straße</th>
                <th>Objektstatus</th>
                <th>Gesamtmiete</th>
                <th>Frei ab</th>
                <th>Exposé</th>
                <th>ImmoSolve</th>
            </tr>

            <tbody>
            {#each angebote as angebot}
                <tr>
                    <td>
                        <button class="btn btn-link btn-sm btn-table" on:click={() => toggleMailStatus(angebot)}>
                            { #if angebot.status === 'WITHHOLD' }
                                Senden
                            { :else if angebot.status === 'DO_SEND'}
                                Zurückhalten
                            {/if}
                        </button>
                    </td>
                    <td class="text-center">
                        { #if angebot.status === 'DO_SEND' }
                            <span data-bs-toggle="tooltip" title="Das Angebot wird zum Kunden gesendet">
                                <ClockIcon class="text-success" size="1.5x"/>
                            </span>
                        { :else if angebot.status === 'WITHHOLD' }
                            <span data-bs-toggle="tooltip" title="Das Angebot wird nicht zum Kunden gesendet">
                                <XCircleIcon class="text-secondary" size="1.5x"/>
                            </span>
                        { :else if angebot.status === 'SENT' || angebot.status === 'SENT_PENDING' }
                            <span data-bs-toggle="tooltip" title="Das Angebot wurde zum Kunden gesendet">
                                <CheckCircleIcon class="text-success" size="1.5x"/>
                            </span>
                        { :else if angebot.status === 'REJECTED' }
                            <span data-bs-toggle="tooltip" title="Kunde hat das Angebot abgelehnt">
                                <ThumbsDownIcon class="text-danger" size="1.5x"/>
                            </span>
                        { :else if angebot.status === 'CONFIRMED_PENDING' || angebot.status === 'CONFIRMED' }
                            <span data-bs-toggle="tooltip" title="Kunde hat das Angebot bestätigt">
                                <ThumbsUpIcon class="text-success" size="1.5x"/>
                            </span>
                        {:else}
                            <label class="switch">
                                <input type="checkbox" disabled>
                                <span class="slider round"></span>
                            </label>
                        {/if}
                    </td>
                    <td>
                        { angebot.flaeche.code }
                    </td>
                    <td>
                        { angebot.flaeche.postleitzahl } { angebot.flaeche.ort }
                    </td>
                    <td>
                        { angebot.flaeche.strassehausnummer }
                    </td>
                    <td>
                        { angebot.flaeche.objektstatus }
                    </td>
                    <td>
                        { euroIntFormatter.format(angebot.flaeche.gesamtmiete / 100) }
                    </td>
                    <td>
                        { angebot.flaeche.freiab }
                    </td>
                    <td class="text-center">
                        {#if angebot.flaeche.objektstatus === 'MARKETABLE' }
                            <a href="{angebot.exposeURL}"
                               target="_blank" rel="noopener noreferrer">
                                <MapIcon class="text-primary" size="1.5x"/>
                            </a>
                        {:else}
                        <span title="Kein Exposé verfügbar" style="cursor: default">
                            <MapIcon class="text-muted" size="1.5x"/>
                        </span>
                        {/if}

                    </td>
                    <td class="text-center">
                        <a href="{angebot.immosolveURL}" target="_blank"
                           rel="noopener noreferrer">
                            <PaperclipIcon class="text-primary" size="1.5x"/>
                        </a>
                    </td>
                </tr>
            {/each}
            </tbody>
        </table>
    </div>

    <button type="button" class="btn btn-info" on:click={ openMailPreview }>Vorschau Mail</button>

</div>


<style>

    .table-wrapper {
        overflow: auto;
    }

    .table {
        --bs-table-hover-bg: #c9f2e3;
        --bs-table-striped-bg: #f3fcf9;
        border-color: #f3fcf9;
        min-width: 1000px;
    }


    tr {
        position: sticky;
        top: 0;
    }

    th {
        width: auto;
        padding: 0.5rem;
    }

    td {
        /* Break words anywhere to make the table fit */
        word-break: break-all;
    }

    .btn-col {
        width: 105px;
    }

    span {
        cursor: pointer;
    }

    .btn-table {
        padding: 0;
        height: 22px;
    }


    .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 22px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 1px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2ab280;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2ab280;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>
