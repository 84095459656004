import de from './de.json';

import {addMessages ,  init, getLocaleFromNavigator } from 'svelte-i18n';
addMessages('de', de);


export function runInit() {
    init({
        fallbackLocale: 'de',
        initialLocale: getLocaleFromNavigator(),
    });
}

