<script lang="ts">

  import {createEventDispatcher, onMount} from 'svelte'
  import type {Auftrag, Benutzer, Gesuch, Zuweisung} from '../../types'
  import {benutzer} from '../../stores/stores'
  import Progress from '../Progress.svelte'
  import {loading, saveZuweisungById} from '../../stores/auftrag-stores'

  export let gesuch: Gesuch
  export let auftrag: Auftrag
  export let edited: boolean

  enum ZuweisungStatus {
    KEIN_AUFTRAG = 'Kein Auftrag',
    BEWERTUNGSTERMIN = 'Bewertungstermin',
    MAKLERVERTRAG = 'Maklervertrag'
  }

  enum ZuweisungHerkuenfte {
    KUNDENBETREUUNG = 'Kundenbetreuung',
    TIPPGEBER = 'Tippgeber',
  }

  const dispatch = createEventDispatcher()

  function close() {
    if (edited && !confirm('Möchten Sie ihre Änderungen verwerfen?')) {
      return
    }
    dispatch('close')
  }

  let zuweisungStatus: { label: string, value: string }[] = enumToArray<ZuweisungStatus>(ZuweisungStatus)

  let herkuenfte: { label: string, value: string }[] = enumToArray<ZuweisungHerkuenfte>(ZuweisungHerkuenfte)

  let bearbeiters: Benutzer[]

  function enumToArray<E>(e: typeof E): { label: string, value: string }[] {
    return Object.entries<E>(e).map((entry: [string, string]) => ({label: entry[1], value: entry[0]}))
  }

  async function saveZuweisung(): Promise<void> {
    const zuweisung: Zuweisung = {...auftrag.zuweisung, systemWiedervorlageGrund: null, systemWiedervorlageDate: null, benutzerName: null}
    const savedZuweisung = await saveZuweisungById(gesuch.id, zuweisung)
    auftrag.zuweisung = {...auftrag.zuweisung, ...savedZuweisung}
    edited = false
  }

  let wiedervorlageFilled: boolean | undefined

  function resetWiedervorlage(): void {
    wiedervorlageFilled = false
    auftrag.zuweisung.bearbeiterWiedervorlageDate = undefined
    auftrag.zuweisung.bearbeiterWiedervorlageGrund = undefined
    edited = true
  }

  function resizeTextarea(): void {
    this.style.height = 'auto'
    this.style.height = (this.scrollHeight + 2) + 'px'
  }

  $: valid = isValid(auftrag!.zuweisung)

  function isValid(zuweisung: Zuweisung): boolean {
    return !!zuweisung.benutzerId && !!zuweisung.status && !!zuweisung.herkunft
  }

  onMount(() => {
    wiedervorlageFilled = !!auftrag.zuweisung.bearbeiterWiedervorlageDate || !!auftrag.zuweisung.bearbeiterWiedervorlageGrund
    benutzer.subscribe((value: Benutzer[]) => bearbeiters = value)
  })

</script>

{#if !bearbeiters || !bearbeiters.length}
    <Progress/>
{:else}
    <div class="modal-body">
        <div class="row mb-3">
            <span class="col-4 col-lg-3 col-form-label">Name:</span>
            <span class="col-8 col-lg-9 col-form-label">{gesuch.name}</span>
        </div>
        <div class="row mb-3">
            <span class="col-4 col-lg-3 col-form-label">Email:</span>
            <span class="col-8 col-lg-9 col-form-label">{gesuch.email}</span>
        </div>
        <div class="row mb-3">
            <label class="col-4 col-lg-3 col-form-label required" for="auftrag-bearbeiter">Bearbeiter:</label>
            <div class="col-8 col-lg-9">
                <select bind:value={auftrag.zuweisung.benutzerId} id="auftrag-bearbeiter"
                        disabled={$loading || auftrag.abgeschlossen}
                        class="form-select form-select-sm" on:change={() => edited = true}>
                    <option value={null} disabled selected={!auftrag.zuweisung.benutzerId}>Bitte auswählen</option>
                    {#each bearbeiters as bearbeiter}
                        <option value={bearbeiter.id}>{bearbeiter.name}</option>
                    {/each}
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-4 col-lg-3 col-form-label required" for="auftrag-status">Status:</label>
            <div class="col-8 col-lg-9">
                <select bind:value={auftrag.zuweisung.status} id="auftrag-status"
                        disabled={$loading || auftrag.abgeschlossen}
                        class="form-select form-select-sm" on:change={() => edited = true}>
                    <option value={null} disabled selected={!auftrag.zuweisung.status}>Bitte auswählen</option>
                    {#each zuweisungStatus as status}
                        <option value={status.value}>{status.label}</option>
                    {/each}
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-4 col-lg-3 col-form-label required" for="auftrag-herkunft">
                Herkunft:
            </label>
            <div class="col-8 col-lg-9">
                <select bind:value={auftrag.zuweisung.herkunft} id="auftrag-herkunft"
                        disabled={$loading || auftrag.abgeschlossen}
                        class="form-select form-select-sm" on:change={() => edited = true}>
                    <option value={null} disabled selected={!auftrag.zuweisung.herkunft}>Bitte auswählen</option>
                    {#each herkuenfte as herkunft}
                        <option value={herkunft.value}>{herkunft.label}</option>
                    {/each}
                </select>
            </div>
        </div>

        <hr>

        <div class="row mb-3">
            <div class="col">
                <div class="row mb-3">
                    <h5 class="col-4 col-lg-3">Wiedervorlage</h5>
                    <div class="col-8 col-lg-9">
                        <button id="auftrag-marked-as-done"
                                disabled={$loading || !wiedervorlageFilled || auftrag.abgeschlossen}
                                class="btn {wiedervorlageFilled ? 'btn-primary' : 'btn-outline-secondary'} btn-sm"
                                on:click={resetWiedervorlage}>
                            Wiedervorlage erledigt
                        </button>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-4 col-lg-3 col-form-label" for="auftrag-wiedervorlage">
                        Datum:
                    </label>
                    <div class="col-8 col-lg-9">
                        <input class="date-picker form-control"
                               id="auftrag-wiedervorlage"
                               type="date"
                               name="wiedervorlage"
                               disabled={$loading || auftrag.abgeschlossen}
                               on:change={() => edited = true}
                               bind:value={auftrag.zuweisung.bearbeiterWiedervorlageDate}/>
                    </div>
                </div>
                <div class="row mb-3">
                    <label class="col-4 col-lg-3 col-form-label" for="floatingTextareaGrund">
                        Grund
                    </label>
                    <div class="col-8 col-lg-9">
                        <textarea
                                bind:value={auftrag.zuweisung.bearbeiterWiedervorlageGrund}
                                class="form-control textarea"
                                placeholder="Grund für Wiedervorlage"
                                id="floatingTextareaGrund"
                                disabled={$loading || auftrag.abgeschlossen}
                                on:change={() => edited = true}
                                on:input={resizeTextarea}
                        ></textarea>
                    </div>
                </div>
                {#if auftrag.zuweisung.systemWiedervorlageDate}
                    <h6>System-Wiedervorlage</h6>
                    <div class="row ">
                        <span class="col-4 col-lg-3 col-form-label">Datum:</span>
                        <span class="col-8 col-lg-9 col-form-label">{auftrag.zuweisung.systemWiedervorlageDate}</span>
                    </div>
                    <div class="row">
                        <span class="col-4 col-lg-3 col-form-label">Grund:</span>
                        <span class="col-8 col-lg-9 col-form-label">{auftrag.zuweisung.systemWiedervorlageGrund || 'N.A.'}</span>
                    </div>
                {/if}
            </div>
        </div>

        <hr>

        <div class="row mb-3">
            <label class="col-4 col-lg-3 col-form-label" for="floatingTextareaKommentar">
                Kommentar
            </label>
            <div class="col-8 col-lg-9">
                        <textarea
                                bind:value={auftrag.zuweisung.kommentar}
                                class="form-control textarea"
                                placeholder=""
                                id="floatingTextareaKommentar"
                                disabled={$loading || auftrag.abgeschlossen}
                                on:change={() => edited = true}
                                on:input={resizeTextarea}
                        ></textarea>
            </div>
        </div>
    </div>
{/if}
<div class="modal-footer">
    <button type="button"
            class="btn btn-secondary"
            on:click={close}>
        {edited ? 'Abbrechen' : 'Schließen'}
    </button>
    <button
            class="btn btn-primary"
            disabled={!valid || $loading || !edited}
            on:click={saveZuweisung}
            type="button"
    >Speichern
    </button>
</div>

<style>
    select {
        background-color: white;
        width: 170px;
        height: 34px;
    }

    label {
        height: 34px;
        line-height: 20px;
    }

    label.required:after {
        content:"*";
        color:red;
    }

    .textarea {
        max-height: 10rem;
        height: auto;
        resize: none;
    }
</style>
