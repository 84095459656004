<script>
    import {filterAbgeschlossen} from '../stores/stores'
    import {signOut} from '../auth'
    import {link} from 'svelte-spa-router'
    import active from 'svelte-spa-router/active'

    function toggleFilterAbgeschlossen(checked) {
        filterAbgeschlossen.set(checked)
    }
</script>

<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
        <div class="navbar-collapse">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 w-100">
                <li class="nav-item">
                    <a href="/gesuche" use:link use:active class="nav-link active" aria-current="page">Gesuche</a>
                </li>
                <li class="nav-item">
                    <a href="/flaechen" use:link use:active class="nav-link" aria-current="page">Flächen</a>
                </li>
                <li class="nav-item">
                    <span class="nav-link" style="cursor: default">Version 1.0</span>
                </li>
                <li class="nav-item separator">
                    <label class="nav-link" for="filterAbgeschlossen">
                        <input class="form-check-input" id="filterAbgeschlossen" type="checkbox" checked="{$filterAbgeschlossen}"
                               on:click={event => toggleFilterAbgeschlossen(event.target.checked)}>
                        Filter Abgeschlossen
                    </label>
                </li>
                <li class="m-0 ms-lg-auto">
                    <span class="nav-link" aria-current="page" on:click={signOut}>Abmelden</span>
                </li>
            </ul>
        </div>
    </div>
</nav>

<!--<header class="navbar">
	<section class="navbar-section">
		<span class="h1 bg-secondary text-primary navbar-brand ml-2">
			Immosolve Anwendung [dev]
		</span>
	</section>
	<section class="navbar-section">
		<Filter/>
	</section>
	<section class="navbar-section">
		<div class="btn-group btn-group-block mt-1 mr-1 mb-1">
			<button class="btn btn-primary" on:click={() => push("/gesuche")}>
				<i class="icon icon-search" /> Gesuche
			</button>
			<button class="btn btn-primary" on:click={() => push("/flaechen")}>
				<i class="icon icon-location" /> Flächen
			</button>
			<button class="btn" on:click={() => push("/fetch")}>
				{#if $progress}
					<i class="icon icon-refresh loader" /> Fetch manuell triggern
				{:else}
					<i class="icon icon-download" /> Fetch manuell triggern
				{/if}
			</button>
		</div>
	</section>
</header>-->
<style>
    .navbar-light .navbar-nav .nav-link {
        color: #575756;
    }

    .navbar-light .navbar-nav .nav-link:hover,
    .navbar-light .navbar-nav .nav-link.active {
        color: #ad2221;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @media (min-width: 992px) {
        .separator {
            border-left: 1px solid #a3a3a3;
            margin-left: 10px;
            padding-left: 10px;
        }
    }

    .nav-link {
        cursor: pointer;
    }
</style>
