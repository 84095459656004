<script lang="ts">

  import {UploadIcon} from 'svelte-feather-icons'
  import {sendIMVRecord} from '../stores/auftrag-stores'

  let validExtensions = ['csv']
  let file: File | undefined
  let result: { status: string, message: string }
  let input: HTMLInputElement

  function onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement
    if (target.files && target.files.length) {
      const selectedFile: File = target.files![0]
      const extension: string = selectedFile.name.split(/\.(?=[^.]+$)/).pop()
      console.log(extension)
      if (validExtensions.includes(extension)) {
        file = selectedFile
      }
    }
  }

  async function importIWMFile(): Promise<void> {
    if (!file) {
      return
    }
    const data: FormData = new FormData()
    data.append('file', file)
    await sendIMVRecord(data)
        .then(response => {
          console.log(response);
          result = {status: 'success', message: 'IMV Import erfolgreich: ' + JSON.stringify(response)}
          resetInput()
        })
        .catch((e) => result = {status: 'danger', message: e})
  }

  function resetInput(): void {
    file = undefined
    input.value = ''
  }
</script>
<button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#imvImportDialog">
    <UploadIcon size="1x"/>
    IMV Import
</button>

<div
        class="modal fade"
        id="imvImportDialog"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="imvImportDialogLabel"
        aria-hidden="true"
>
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="imvImportDialogLabel">IMV Import</h5>
                <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        on:click={resetInput}
                ></button>
            </div>

            <div class="modal-body">
                <div class="mb-3">
                    {#if result}
                        <div class="{'alert alert-' + result.status}" role="alert">
                            {result.message}
                        </div>
                    {/if}
                    <label for="imvImportFile" class="form-label">Bitte wählen Sie eine Datei aus.</label>
                    <input class="form-control" type="file" id="imvImportFile"
                           bind:this={input}
                           accept="{validExtensions.map(e => '.' + e).join(' ')}"
                           on:change={(e)=>onFileSelected(e)}>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        on:click={resetInput}>
                    {#if file}
                        Abbrechen
                    {:else}
                        Schließen
                    {/if}
                </button>
                <button
                        class="btn btn-primary"
                        disabled={!file}
                        type="button"
                        on:click={importIWMFile}
                >Senden
                </button>
            </div>

        </div>
    </div>
</div>
