import Gesuche from './routes/Gesuche.svelte'
import Flaechen from './routes/Flaechen.svelte'
import NotFound from './routes/NotFound.svelte'
import Login from './routes/Login.svelte'
import {wrap} from 'svelte-spa-router/wrap'
import {isAuthenticated} from './stores/stores'
import {get} from 'svelte/store'

const isAuthenticatedCondition = () => {
    return get(isAuthenticated)
}

export default {
    '/': wrap({
        component: Gesuche,
        conditions: [isAuthenticatedCondition]
    }),
    '/gesuche': wrap({
        component: Gesuche,
        conditions: [isAuthenticatedCondition]
    }),
    '/flaechen': wrap({
        component: Flaechen,
        conditions: [isAuthenticatedCondition]
    }),
    '/login': wrap({
        component: Login,
        conditions: [
            () => {
                return !get(isAuthenticated)
            }
        ]
    }),
    '*': wrap({
        component: NotFound,
        conditions: [isAuthenticatedCondition]
    })
};
