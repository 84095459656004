/**
 * Recursively gets a sub field of an object: obj[field[0]][field[1]][field[...]][field[n]].
 * @param obj
 * @param fields
 */
export function getRecursive(obj, fields) {
    // necessary for subfields like auftrag.herkunft
    if (fields.length === 1) {
        return obj[fields[0]];
    }
    if (obj[fields[0]]) {
        return getRecursive(obj[fields[0]], fields.slice(1));
    }
    else {
        return "";
    }
}
