<script lang="ts">
  import {fetchFlaechen, flaechenFilter, flaechenAll, refreshing} from '../stores/stores'
  import Progress from '../components/Progress.svelte'
  import {onMount} from 'svelte'
  import FlaechenTable from '../components/FlaechenTable/FlaechenTable.svelte'
  import type {Flaeche} from '../types'
  import ListHeader from '../components/ListHeader.svelte'
  import IMWImport from '../components/IMVImport.svelte'

  export const CUSTOM_FILTER_COLUMNS: Map<string, (value: number | null, compareTo: string) => boolean> = new Map([
    ['gesamtmiete', (value, compareTo) => !value ? false : value?.toString().includes(compareTo)],
  ])

  let flaechen: Flaeche[]
  let flaechenShown: Flaeche[] = []
  let error

  onMount(() => {
    flaechenAll.subscribe(flaechenArray => flaechen = flaechenArray)
    fetchFlaechen().catch(e => error = e)
  })

  function filterFlaechen(value: string, columnName: string): void {
    flaechenShown = (flaechenShown || []).filter((flaeche) => {
          if (CUSTOM_FILTER_COLUMNS.has(columnName)) {
            return CUSTOM_FILTER_COLUMNS.get(columnName)!(flaeche[columnName], value)
          } else {
            if (flaeche[columnName]) {
              return String(flaeche[columnName]).toLowerCase().includes(value.toLowerCase())
            } else {
              return false
            }
          }
        }
    )
  }

  $: {
    flaechenShown = flaechen
    $flaechenFilter.forEach(filterFlaechen)
  }

</script>

{#if $refreshing}
    <Progress/>
{:else if error}
    <p class="error">Error: {error.message}</p>
    <button on:click={() => fetchFlaechen()}>Erneut versuchen</button>
{:else}
    <ListHeader title="Flächen" on:refresh={() => fetchFlaechen(true).catch(e => error = e)}>
        <IMWImport></IMWImport>
    </ListHeader>
    <FlaechenTable flaechen={flaechenShown}/>
{/if}

<style>
</style>
