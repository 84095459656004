export const euroIntFormatter = Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
});
export var GesuchTableColumn;
(function (GesuchTableColumn) {
    GesuchTableColumn["name"] = "name";
    GesuchTableColumn["prioritaet"] = "prioritaet";
    GesuchTableColumn["bearbeiter"] = "auftrag.zuweisung.benutzerName";
    GesuchTableColumn["ausstehend"] = "auftrag.countDoSend";
    GesuchTableColumn["interesseAn"] = "auftrag.countConfirmedWaiting";
    GesuchTableColumn["systemWiedervorlage"] = "auftrag.zuweisung.systemWiedervorlageGrund";
    GesuchTableColumn["bearbeiterWiedervorlage"] = "auftrag.zuweisung.bearbeiterWiedervorlageGrund";
    GesuchTableColumn["gesuchaktiv"] = "gesuchaktiv";
    GesuchTableColumn["gesuchvollstaendig"] = "gesuchvollstaendig";
    GesuchTableColumn["aufgenommen"] = "aufgenommen";
    GesuchTableColumn["neuvermietungsstatus"] = "neuvermietungsstatus";
    GesuchTableColumn["suchtseit"] = "suchtseit";
    GesuchTableColumn["anmietungswunsch"] = "anmietungswunsch";
    GesuchTableColumn["raeumevon"] = "raeumevon";
    GesuchTableColumn["flaechevon"] = "flaechevon";
    GesuchTableColumn["gesamtmietebis"] = "gesamtmietebis";
    GesuchTableColumn["email"] = "email";
    GesuchTableColumn["neu"] = "neu";
})(GesuchTableColumn || (GesuchTableColumn = {}));
export var FlaecheTableColumn;
(function (FlaecheTableColumn) {
    FlaecheTableColumn["code"] = "code";
    FlaecheTableColumn["strassehausnummer"] = "strassehausnummer";
    FlaecheTableColumn["postleitzahl"] = "postleitzahl";
    FlaecheTableColumn["ort"] = "ort";
    FlaecheTableColumn["etage"] = "etage";
    FlaecheTableColumn["objektstatus"] = "objektstatus";
    FlaecheTableColumn["objektstatusseit"] = "objektstatusseit";
    FlaecheTableColumn["raeume"] = "raeume";
    FlaecheTableColumn["gesamtmiete"] = "gesamtmiete";
    FlaecheTableColumn["freiab"] = "freiab";
    FlaecheTableColumn["verfuegbarab"] = "verfuegbarab";
    FlaecheTableColumn["sachbearbeiter"] = "sachbearbeiter";
})(FlaecheTableColumn || (FlaecheTableColumn = {}));
export class Auftrag {
    constructor(auftrag) {
        this.id = null;
        this.lastModifiedDate = null;
        this.createdDate = null;
        this.angebote = [];
        this.verkaufsobjekt = new Verkaufsobjekt();
        this.zuweisung = new Zuweisung();
        Object.assign(this, auftrag);
    }
}
export class Zuweisung {
    constructor(benutzerId, benutzerName, status, herkunft, kommentar, bearbeiterWiedervorlageDate, bearbeiterWiedervorlageGrund, systemWiedervorlageDate = null, systemWiedervorlageGrund = null) {
        this.benutzerId = benutzerId;
        this.benutzerName = benutzerName;
        this.status = status;
        this.herkunft = herkunft;
        this.kommentar = kommentar;
        this.bearbeiterWiedervorlageDate = bearbeiterWiedervorlageDate;
        this.bearbeiterWiedervorlageGrund = bearbeiterWiedervorlageGrund;
        this.systemWiedervorlageDate = systemWiedervorlageDate;
        this.systemWiedervorlageGrund = systemWiedervorlageGrund;
    }
}
export class Verkaufsobjekt {
    constructor(eigentumStrasseHausnr, eigentumPlz, eigentumStadt, einkaeufer, einkaeuferEmail, einkaeuferKontaktaufnahme, bewertungsdatum, maklerauftragserteilung) {
        this.eigentumStrasseHausnr = eigentumStrasseHausnr;
        this.eigentumPlz = eigentumPlz;
        this.eigentumStadt = eigentumStadt;
        this.einkaeufer = einkaeufer;
        this.einkaeuferEmail = einkaeuferEmail;
        this.einkaeuferKontaktaufnahme = einkaeuferKontaktaufnahme;
        this.bewertungsdatum = bewertungsdatum;
        this.maklerauftragserteilung = maklerauftragserteilung;
    }
}
