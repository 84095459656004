<script lang="ts">
  import Navbar from './components/Navbar.svelte'
  import Router, {replace} from 'svelte-spa-router'
  import routes from './routes'
  import {runInit} from './i18n/i18n'
  import {isAuthenticated} from './stores/stores'
  import {onMount} from 'svelte'
  import {loadAuthModule} from './auth'

  runInit()

  function conditionsFailed(event) {
    if (event.detail.route == '/login') {
      replace('/')
    } else {
      replace('/login')
    }
  }

  onMount(async () => {
    loadAuthModule()
  })

</script>

<meta name="viewport" content="width=device-width, initial-scale=1"/>

<main>
    {#if $isAuthenticated}
        <Navbar/>
    {/if}
    <div class="page-container">
        <Router {routes} on:conditionsFailed={conditionsFailed}/>
    </div>
</main>

<style>
    main {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .page-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 56px);
    }
</style>
