<script lang="ts">
  import {createEventDispatcher} from 'svelte'
  import {RefreshCwIcon} from 'svelte-feather-icons'

  export let title = ''

  const refreshList = createEventDispatcher<{ refresh: null }>()

  function refresh() {
    refreshList('refresh', null)
  }
</script>
<div class="list-header-container">
    <p class="h2 list-header">{title}</p>
    <div>
        <slot></slot>
        <button type="button" class="btn btn-secondary" on:click={refresh}>
            <RefreshCwIcon class="btn-icon"  size="1x"/>Fetch manuell triggern
        </button>
    </div>
</div>

<style>
    .list-header-container {
        display: flex;
        justify-content: space-between;
        margin: 0 1em;
        align-items: center;
    }

    .list-header {
        margin-bottom: 0;
    }
</style>
