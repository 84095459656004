import { get, writable } from 'svelte/store';
import EnvService from '../env.service';
export const env = EnvService();
export const refreshing = writable(false);
export const gesucheFilter = writable(new Map());
export const flaechenFilter = writable(new Map());
export const filterAbgeschlossen = writable(true);
export const gesucheAll = writable([]);
export let gesucheReadOnly;
export const flaechenAll = writable([]);
export const benutzer = writable([]);
export const isAuthenticated = writable(false);
export const user = writable(null);
export const accessToken = writable('');
export const apiBaseUrl = `${get(env).apiHost}/${get(env).apiUrl}`;
function authHeader(type) {
    const headers = new Headers({ Authorization: `Bearer ${get(accessToken)}`, referrerPolicy: 'same-origin' });
    if (type) {
        headers.set('Content-Type', 'application/json');
    }
    return { headers };
}
export async function fetchWithAccessToken(path, method = 'GET', body, formData = false) {
    return fetch(apiBaseUrl + path, Object.assign(Object.assign({}, authHeader(!formData)), { method, body: formData ? body : JSON.stringify(body) }));
}
export async function fetchGesuche(refresh = false) {
    refreshing.set(true);
    gesucheAll.set([]);
    const response = await fetchWithAccessToken('gesuche' + (refresh ? '?refresh=true' : ''));
    if (response.ok) {
        const fetchedGesuche = await response.json();
        gesucheAll.set(fetchedGesuche);
    }
    else {
        throw new Error(refresh ? 'refresh ' : '' + 'fetchGesuche failed');
    }
    refreshing.set(false);
}
export async function fetchGesuch(id) {
    const response = await fetchWithAccessToken('gesuche/' + id);
    if (response.ok) {
        const gesuch = await response.json();
        gesucheAll.update(gesuche => {
            gesuche.splice(gesuche.findIndex(gesuch => gesuch.id === id), 1, gesuch);
            gesucheReadOnly = gesuche;
            return gesuche;
        });
        return gesuch;
    }
    else {
        throw new Error('fetchGesuch failed');
    }
}
export async function fetchFlaechen(refresh = false) {
    refreshing.set(true);
    flaechenAll.set([]);
    const response = await fetchWithAccessToken('flaechen' + (refresh ? '?refresh=true' : ''));
    if (response.ok) {
        const fetchedFlaechen = await response.json();
        flaechenAll.set(fetchedFlaechen);
    }
    else {
        throw new Error(refresh ? 'refresh ' : '' + 'fetchFlaechen failed');
    }
    refreshing.set(false);
}
export async function fetchBenutzer() {
    const response = await fetchWithAccessToken('benutzer');
    if (response.ok) {
        const fetchedBenutzer = await response.json();
        benutzer.set(fetchedBenutzer);
        return;
    }
    throw new Error('fetchBenutzer failed');
}
export async function fetchAuftrag(id) {
    const response = await fetchWithAccessToken('gesuche/' + id + '/auftrag');
    if (response.ok) {
        return response.json();
    }
    throw new Error('fetchAuftrag failed');
}
export async function putGesuchAuftrag(id, auftrag) {
    auftrag.createdDate = null;
    auftrag.lastModifiedDate = null;
    const response = await fetchWithAccessToken('gesuche/' + id + '/auftrag', 'PUT', auftrag);
    if (response.ok) {
        return response;
    }
    throw new Error('putBearbeiter failed');
}
export async function fetchAngebote(auftragId, refresh = false) {
    const response = await fetchWithAccessToken(`gesuche/${auftragId}/auftrag/angebote?refresh=` + (refresh ? 'true' : 'false'));
    if (response.ok) {
        return response.json();
    }
    else if (response.status === 404) {
        return [];
    }
    throw new Error('fetchAngebote failed');
}
