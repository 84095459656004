<script lang="ts">
  import type {Flaeche} from '../../types'
  import {FlaecheTableColumn} from '../../types'
  import FlaechenTableHead from './FlaechenTableHead.svelte'
  import FlaechenTableBody from './FlaechenTableBody.svelte'

  export let flaechen: Flaeche[] = []

  let sort: { column: FlaecheTableColumn, ascending: boolean }
      = {column: FlaecheTableColumn.freiab, ascending: false}

  $: sortedGesuche = flaechen.sort((row1: Flaeche, row2: Flaeche) =>
      doSort(row1, row2, sort.column, sort.ascending,)
  )

  const doSort = (row1: Flaeche, row2: Flaeche, column: FlaecheTableColumn, ascending: boolean): number => {
    const value1 = row1[column]
    const value2 = row2[column]
    if (!value1 && value2) {
      return ascending ? -1 : 1
    } else if (value1 && !value2) {
      return ascending ? 1 : -1
    } else if (value1 > value2) {
      return ascending ? 1 : -1
    } else if (value1 < value2) {
      return ascending ? -1 : 1
    }
    return 0
  }

  const handleSortColumnChange = (tableColumn: FlaecheTableColumn): void => {
    if (sort.column == tableColumn) {
      sort.ascending = !sort.ascending
    } else {
      sort.column = tableColumn
      sort.ascending = true
    }
  }

</script>

<div class="scroll">
    <table class="table table-striped table-hover">
        <FlaechenTableHead sortedColumn={sort.column} isAscending={sort.ascending} onSortColumnChange={handleSortColumnChange}/>
        <FlaechenTableBody flaechen={sortedGesuche}/>
    </table>
</div>


<style>
</style>
