<script lang="ts">
    import type {Flaeche} from '../../types';
    import {FlaecheTableColumn, euroIntFormatter} from '../../types';

    export let flaeche: Flaeche;

</script>

<tr>
    {#each Object.entries(FlaecheTableColumn).map(([k, v]) => [k, flaeche[k], flaeche[v]]) as [key, keyValue, value]}
            <td nowrap>
            {#if key === 'gesamtmiete'}
                {keyValue === null ? '' : euroIntFormatter.format(keyValue / 100)}
            {:else}
                {value ? value : ''}
            {/if}
            </td>
    {/each}
</tr>


