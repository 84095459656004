<script lang="ts">
  import TableBody from './GesuchTableBody.svelte'
  import TableHead from './GesuchTableHead.svelte'
  import type {Gesuch} from '../../types'
  import {GesuchTableColumn} from '../../types'
  import {getRecursive} from '../../util'

  export let gesuche: Gesuch[] = []

  // Holds table sort state. Initialized to reflect table sorted by id column ascending.
  let sort: { column: GesuchTableColumn, ascending: boolean } =
      {column: GesuchTableColumn.aufgenommen, ascending: false}

  $: sortedGesuche = gesuche.sort((row1: Gesuch, row2: Gesuch) =>
      doSort(row1, row2, sort.column, sort.ascending)
  )

  const doSort = (row1: Gesuch, row2: Gesuch, column: GesuchTableColumn, ascending: boolean): number => {
    const value1 = getRecursive(row1, column.split("."))
    const value2 = getRecursive(row2, column.split("."))
    if (!value1 && value2) {
      return ascending ? -1 : 1
    } else if (value1 && !value2) {
      return ascending ? 1 : -1
    } else if (value1 > value2) {
      return ascending ? 1 : -1
    } else if (value1 < value2) {
      return ascending ? -1 : 1
    }
    return 0
  }

  const handleSortColumnChange = (tableColumn: GesuchTableColumn): void => {
    if (sort.column == tableColumn) {
      sort.ascending = !sort.ascending
    } else {
      sort.column = tableColumn
      sort.ascending = true
    }
  }
</script>

<div class="scroll">
    <table class="table table-striped table-hover">
        <TableHead sortedColumn={sort.column} isAscending={sort.ascending} onSortColumnChange={handleSortColumnChange}/>
        <TableBody gesuche={sortedGesuche}/>
    </table>
</div>
