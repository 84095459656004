import { get } from 'svelte/store';
import { accessToken, env, isAuthenticated, user } from './stores/stores';
import { PublicClientApplication } from '@azure/msal-browser';
import { replace } from 'svelte-spa-router';
//const scope = '18ff6c2b-c314-4044-8f48-c2056f4b0333/.default'
const scope = `${get(env).authConfig.clientId}/.default`;
const MSAL_CONFIG = {
    auth: get(env).authConfig,
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    }
};
const loginRequest = {
    scopes: [scope]
};
const myMSALObj = new PublicClientApplication(MSAL_CONFIG);
function getAccount() {
    const currentAccounts = myMSALObj.getAllAccounts();
    if (currentAccounts === null || currentAccounts.length === 0 || currentAccounts.length > 1) {
        isAuthenticated.set(false);
        return;
    }
    else if (currentAccounts.length === 1) {
        isAuthenticated.set(true);
        user.set(currentAccounts[0]);
        getToken(currentAccounts[0]);
    }
}
function handleResponse(resp) {
    if (resp !== null) {
        isAuthenticated.set(true);
        user.set(resp.account);
        accessToken.set(resp.accessToken);
        replace('/');
    }
    else {
        getAccount();
    }
}
function getToken(account) {
    const accessTokenRequest = {
        scopes: [scope],
        account: account
    };
    myMSALObj.acquireTokenSilent(accessTokenRequest).then(res => {
        accessToken.set(res.accessToken);
        replace('/');
    }).catch(e => {
        console.error(e);
        isAuthenticated.set(false);
        user.set(null);
        accessToken.set('');
        replace('/login');
    });
}
function signIn() {
    myMSALObj.loginRedirect(loginRequest);
}
function signOut() {
    const logoutRequest = {
        account: myMSALObj.getAccountByUsername(get(user).username)
    };
    myMSALObj.logoutRedirect(logoutRequest).then(() => {
        isAuthenticated.set(false);
        user.set(null);
        accessToken.set('');
        replace('/login');
    });
}
function loadAuthModule() {
    myMSALObj.handleRedirectPromise().then((resp) => {
        handleResponse(resp);
    }).catch(console.error);
}
export { loadAuthModule, signIn, signOut };
