<script lang="ts">
  import type {Auftrag, Gesuch} from '../../types'
  import {createEventDispatcher} from 'svelte'
  import {loading, saveVerkaufsobjektById} from '../../stores/auftrag-stores'

  export let gesuch: Gesuch
  export let auftrag: Auftrag
  export let edited: boolean = false

  const dispatch = createEventDispatcher()

  function close() {
    if (edited && !confirm('Möchten Sie ihre Änderungen verwerfen?')) {
      return
    }
    dispatch('close')
  }

  async function saveVerkaufsobjekt(): Promise<void> {
    auftrag.verkaufsobjekt = await saveVerkaufsobjektById(gesuch.id, auftrag.verkaufsobjekt)
    edited = false
  }

</script>

<div class="modal-body">

    <div class="row mb-2">
        <label class="col-4 col-lg-3 col-form-label" for="auftrag-einkaeufer">
            Einkaeufer
        </label>
        <div class="col-8 col-lg-9">
            <input type="text" class="form-control" id="auftrag-einkaeufer"
                   disabled={$loading || auftrag.abgeschlossen}
                   bind:value={auftrag.verkaufsobjekt.einkaeufer}
                   on:change={() => edited = true}/>
        </div>
    </div>

    <div class="row mb-2">
        <label class="col-4 col-lg-3 col-form-label" for="auftrag-einkaeufer-email">
            Email
        </label>
        <div class="col-8 col-lg-9">
            <input type="text" class="form-control" id="auftrag-einkaeufer-email"
                   disabled={$loading || auftrag.abgeschlossen}
                   bind:value={auftrag.verkaufsobjekt.einkaeuferEmail}
                   on:change={() => edited = true}/>
        </div>
    </div>
    <div class="row mb-2">
        <label class="col-4 col-lg-3 col-form-label" for="auftrag-eigentum-strasse-hausnr">
            Straße, Nr.
        </label>
        <div class="col-8 col-lg-9">
            <input type="text" class="form-control" id="auftrag-eigentum-strasse-hausnr"
                   disabled={$loading || auftrag.abgeschlossen}
                   bind:value={auftrag.verkaufsobjekt.eigentumStrasseHausnr}
                   on:change={() => edited = true}/>
        </div>
    </div>
    <div class="row mb-2">
        <label class="col-4 col-lg-3 col-form-label" for="auftrag-eigentum-plz">
            Plz
        </label>
        <div class="col-8 col-lg-9">
            <input type="text" class="form-control" id="auftrag-eigentum-plz"
                   disabled={$loading || auftrag.abgeschlossen}
                   bind:value={auftrag.verkaufsobjekt.eigentumPlz}
                   on:change={() => edited = true}/>
        </div>
    </div>

    <div class="row mb-2">
        <label class="col-4 col-lg-3 col-form-label" for="auftrag-eigentum-stadt">
            Stadt
        </label>
        <div class="col-8 col-lg-9">
            <input type="text" class="form-control" id="auftrag-eigentum-stadt"
                   disabled={$loading || auftrag.abgeschlossen}
                   bind:value={auftrag.verkaufsobjekt.eigentumStadt}
                   on:change={() => edited = true}/>
        </div>
    </div>

    <div class="row mb-2">
        <label class="col-4 col-lg-3 col-form-label" for="auftrag-einkaeufer-kontaktaufnahme">
            Kontaktaufnahme (Einkaeufer):
        </label>
        <div class="col-8 col-lg-9">
            <input class="date-picker form-control"
                   id="auftrag-einkaeufer-kontaktaufnahme"
                   type="date"
                   name="wiedervorlage"
                   bind:value={auftrag.verkaufsobjekt.einkaeuferKontaktaufnahme}
                   disabled={$loading || auftrag.abgeschlossen}
                   on:change={() => edited = true}/>
        </div>
    </div>

    <div class="row mb-2">
        <label class="col-4 col-lg-3 col-form-label" for="auftrag-bewertungsdatum">
            Bewertungsdatum:
        </label>
        <div class="col-8 col-lg-9">
            <input class="date-picker form-control"
                   id="auftrag-bewertungsdatum"
                   type="date"
                   name="wiedervorlage"
                   bind:value={auftrag.verkaufsobjekt.bewertungsdatum}
                   disabled={$loading || auftrag.abgeschlossen}
                   on:change={() => edited = true}/>
        </div>
    </div>

    <div class="row mb-2">
        <label class="col-4 col-lg-3 col-form-label" for="auftrag-maklerauftragserteilung">
            Maklerauftragserteilung:
        </label>
        <div class="col-8 col-lg-9">
            <input class="date-picker form-control"
                   id="auftrag-maklerauftragserteilung"
                   type="date"
                   name="wiedervorlage"
                   bind:value={auftrag.verkaufsobjekt.maklerauftragserteilung}
                   disabled={$loading || auftrag.abgeschlossen}
                   on:change={() => edited = true}/>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button"
            class="btn btn-secondary"
            on:click={close}>
        {edited ? 'Abbrechen' : 'Schließen'}
    </button>
    <button
            class="btn btn-primary"
            disabled={$loading || !edited || auftrag.abgeschlossen}
            on:click={saveVerkaufsobjekt}
            type="button"
    >Speichern
    </button>
</div>

<style>
    input {
        width: 171px;
        height: 34px;
    }

    input:focus {
        outline: unset;
    }

    label {
        height: 34px;
        line-height: 20px;
    }
</style>
