<script lang="ts">
  import type {Auftrag, Gesuch} from '../../types'
  import Zuweisung from './ZuweisungTab.svelte'
  import Tab2 from './VerkaufsobjektTab.svelte'
  import Tab3 from './AngeboteTab.svelte'
  import {createEventDispatcher, onMount} from 'svelte'
  import {getAuftrag, toggleAuftragAbgeschlossenById} from '../../stores/auftrag-stores'
  import Progress from '../Progress.svelte'
  import {LockIcon, UnlockIcon} from 'svelte-feather-icons'
  import {fetchBenutzer} from '../../stores/stores'

  export let gesuch: Gesuch

  let auftrag: Auftrag | undefined

  let edited: boolean = false

  let activeTab: number = 1

  let newAuftrag: boolean | undefined = undefined

  onMount(async () => {
    getAuftrag(gesuch.id).then(res => {
      newAuftrag = res.id === null
      auftrag = res
    }).catch(e => console.error(e))
    await fetchBenutzer()
  })

  const dispatch = createEventDispatcher()

  function close() {
    dispatch('close')
  }

  async function toggleAuftragAbgeschlossen(): Promise<void> {
    auftrag!.abgeschlossen = await toggleAuftragAbgeschlossenById(gesuch.id, auftrag!)
  }

  $: tabs = [
    {
      label: 'Zuweisung',
      value: 1,
      component: Zuweisung,
    },
    {
      label: 'VIP-Verkaufsobjekt',
      value: 2,
      component: Tab2
    },
    {
      label: 'Matchings (Angebote)',
      value: 3,
      component: Tab3,
    }
  ]

</script>

<div
        class="modal fade"
        id="editDialog"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="editDialogLabel"
        aria-hidden="true"
>
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="editDialogLabel">{newAuftrag === undefined ? 'Auftrag' : newAuftrag ? 'Neuer Auftrag' : 'Auftrag bearbeiten'}</h5>
                {#if auftrag && auftrag.id}
                    <button
                            type="button"
                            class="btn btn-success"
                            id="auftrag-toggle"
                            disabled={edited}
                            on:click={toggleAuftragAbgeschlossen}>
                        {#if auftrag.abgeschlossen}
                            <UnlockIcon class="btn-icon" size="1x"/>Auftrag öffnen
                        {:else}
                            <LockIcon class="btn-icon" size="1x"/>Auftrag abschließen
                        {/if}
                    </button>
                {/if}
                <button
                        type="button"
                        class="btn-close"
                        on:click={close}
                        aria-label="Close"
                ></button>
            </div>
            {#if auftrag}
                <ul class="nav nav-tabs">
                    {#each tabs as tab}
                        <li class="nav-item">
                            <a class="nav-link"
                               class:active="{activeTab === tab.value}"
                               class:disabled="{edited}"
                               href="javascript:void(0)"
                               disabled={edited}
                               on:click={() => activeTab = tab.value}>{tab.label}</a>
                        </li>
                    {/each}
                </ul>
                {#each tabs as item}
                    {#if activeTab === item.value}
                        <svelte:component on:close={close} bind:gesuch={gesuch} bind:auftrag={auftrag}
                                          bind:edited={edited} this={item.component}/>
                    {/if}
                {/each}
            {:else}
                <Progress/>
            {/if}
        </div>
    </div>
</div>

<style>
    .modal-content {
        background-color: white;
    }

    div.modal-header {
        border: none;
    }

    .btn-close {
        margin-left: 0;
    }
</style>
