<script lang="ts">
  import {_ as t} from 'svelte-i18n'
  import {FlaecheTableColumn} from '../../types'
  import {flaechenFilter} from '../../stores/stores'
  import {ArrowDownIcon, ArrowUpIcon} from 'svelte-feather-icons'

  export let sortedColumn: FlaecheTableColumn
  export let isAscending: boolean
  export let onSortColumnChange

  $: getClass = (column: FlaecheTableColumn): string => {
    if (column !== sortedColumn) {
      return ''
    }
    return isAscending ? 'up' : 'down'
  }

  const onClick = (column): void => {
    sortedColumn = column
    onSortColumnChange(sortedColumn)
  }

  const onInputChange = (filterColumn, event): void => {
    let filters = $flaechenFilter
    filters.set(filterColumn, event.target.value.trim())
    flaechenFilter.set(filters)

    if ($flaechenFilter.get(filterColumn) === '') {
      $flaechenFilter.delete(filterColumn)
    }
  }

</script>

<thead>
<tr>
    {#each Object.entries(FlaecheTableColumn) as [key, value] }

        <th>
            <span on:click={() => onClick(value)}>
                {$t('FlaecheTableColumn.' + value)}
                {#if getClass(value) === 'up'}
                    <i><ArrowUpIcon size="1x"/></i>
                {:else if getClass(value) === 'down'}
                    <i><ArrowDownIcon size="1x"/></i>
                {/if}
            </span>
            <input class="form-control form-control-sm"
                   type="text" placeholder="Filtern" value={$flaechenFilter.get(value) || ''}
                   on:input={(event)=>onInputChange(value, event)}/>
        </th>
    {/each}
</tr>
</thead>

<style>

    tr {
        position: sticky;
        background-color: white;
        top: 0;
        white-space: nowrap;
        box-shadow: inset 0 -1px 0 #000000;
    }

    th {
        width: auto;
    }

    span {
        cursor: pointer;
        color: #575756;
    }

    span:hover {
        color: #ad2221;
    }
</style>

