import { Auftrag } from '../types';
import { fetchWithAccessToken } from './stores';
import { writable } from 'svelte/store';
export const loading = writable(false);
let queryCount = 0;
function updateCount(status) {
    queryCount = status ? queryCount + 1 : queryCount - 1;
    if (queryCount <= 0) {
        queryCount = 0;
        return false;
    }
    return true;
}
export async function getAuftrag(id) {
    loading.update(() => updateCount(true));
    const response = await fetchWithAccessToken(`gesuche/${id}/auftrag`, 'GET');
    loading.update(() => updateCount(false));
    if (response.ok) {
        return new Auftrag(await response.json());
    }
    throw new Error('Auftrag abrufen fehlgeschlagen');
}
export async function saveZuweisungById(id, zuweisung) {
    loading.update(() => updateCount(true));
    const response = await fetchWithAccessToken(`gesuche/${id}/auftrag/zuweisung`, 'PUT', zuweisung);
    loading.update(() => updateCount(false));
    if (response.ok) {
        return await response.json();
    }
    throw new Error('Zuweisung speichern fehlgeschlagen');
}
export async function toggleAuftragAbgeschlossenById(id, auftrag) {
    loading.update(() => updateCount(true));
    const response = await fetchWithAccessToken(`gesuche/${id}/auftrag/abgeschlossen`, 'PUT', !auftrag.abgeschlossen);
    loading.update(() => updateCount(false));
    if (response.ok) {
        return await response.json();
    }
    throw new Error('Auftrag abgeschlossen ändern fehlgeschlagen');
}
export async function saveVerkaufsobjektById(id, verkaufsobjekt) {
    loading.update(() => updateCount(true));
    const response = await fetchWithAccessToken(`gesuche/${id}/auftrag/verkaufsobjekt`, 'PUT', verkaufsobjekt);
    loading.update(() => updateCount(false));
    if (response.ok) {
        return await response.json();
    }
    throw new Error('Verkaufsobjekt speichern fehlgeschlagen');
}
export async function sendIMVRecord(data) {
    loading.update(() => updateCount(true));
    const response = await fetchWithAccessToken(`imv-records`, 'POST', data, true);
    loading.update(() => updateCount(false));
    if (response.ok) {
        return await response.json();
    }
    throw new Error('IMW Import fehlgeschlagen');
}
/**
 * Overrides mailStatus for a single Angebot.
 * @param gesuchId The gesuch Id
 * @param angebotHash The Angebot's ID/hash.
 * @param status The new status to set (DO_SEND/WITHHOLD). Does not set status on already SENT items.
 * @returns The new mailStatus.
 */
export async function putAngebotMailStatus(gesuchId, angebotHash, status) {
    if (status === 'SENT') {
        throw new Error(`Angebot ${angebotHash} already sent, refusing request to set to ${status}.`);
    }
    const response = await fetchWithAccessToken(`gesuche/${gesuchId}/auftrag/angebote/${angebotHash}/status`, 'PUT', status);
    if (response.ok) {
        return await response.json();
    }
    throw new Error('putAngebotMailStatus failed');
}
export async function getMailPreview(angebotId) {
    const response = await fetchWithAccessToken(`gesuche/${angebotId}/auftrag/preview`);
    if (response.ok) {
        return window.URL.createObjectURL(await response.blob());
    }
    throw new Error('getMailPreviewAddress failed');
}
