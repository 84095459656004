<script lang="ts">
  import {fetchGesuche, gesucheFilter, gesucheAll, refreshing} from '../stores/stores'
  import GesucheTable from '../components/GesuchTable/GesucheTable.svelte'
  import Progress from '../components/Progress.svelte'
  import {onMount} from 'svelte'
  import type {Gesuch} from '../types'
  import {euroIntFormatter} from '../types'
  import ListHeader from '../components/ListHeader.svelte'
  import {getRecursive} from '../util'

  export const CUSTOM_COLUMN_FILTER: Map<string, (value: number | null, compareTo: number | string) => boolean> = new Map([
    ['gesamtmietebis', (value, compareTo) => !value ? false : value / 100 <= compareTo],
    ['raeumevon', (value, compareTo) => !value ? false : value >= compareTo],
    ['flaechevon', (value, compareTo) => !value ? false : value >= compareTo],
  ])

  let gesuche: Gesuch[]
  let gesucheShown: Gesuch[] = []
  let error

  onMount(() => {
    gesucheAll.subscribe((gesucheArray) => gesuche = gesucheArray)
    fetchGesuche().catch(e => error = e)
  })

  function filterGesuche(value: string, columnName: string): void {
    gesucheShown = (gesucheShown || []).filter((gesuch) => {
          if (CUSTOM_COLUMN_FILTER.has(columnName)) {
            return CUSTOM_COLUMN_FILTER.get(columnName)!(gesuch[columnName], value)
          } else {
            const columnValue = getRecursive(gesuch, columnName.split('.'))
            if (columnValue) {
              return String(columnValue).toLowerCase().includes(value.toLowerCase())
            } else {
              return false
            }
          }
        }
    ).map(gesuch => {
      const gmb = gesuch.gesamtmietebis
      gesuch.gesamtmietebis = isNaN(+gmb) ? gmb : euroIntFormatter.format(gmb / 100)
      return gesuch
    })
  }

  $: {
    gesucheShown = gesuche
    $gesucheFilter.forEach(filterGesuche)
  }

</script>

{#if $refreshing}
    <Progress/>
{:else if error}
    <p class="error">Error: {error.message}</p>
    <button on:click={() => fetchGesuche()}>Erneut versuchen</button>
{:else}
    <ListHeader title="Gesuche" on:refresh={() => fetchGesuche(true).catch(e => error = e)}></ListHeader>
    <GesucheTable gesuche={gesucheShown}/>
{/if}

<style>
</style>
