<script lang="ts">
  import {GesuchTableColumn} from '../../types'
  import {gesucheFilter} from '../../stores/stores'
  import {_ as t} from 'svelte-i18n'
  import {ArrowDownIcon, ArrowUpIcon} from 'svelte-feather-icons'

  export let sortedColumn: GesuchTableColumn;
    export let isAscending: boolean;
    export let onSortColumnChange;

    $: getClass = (column: GesuchTableColumn): string => {
        if (column !== sortedColumn) {
            return '';
        }
        return isAscending ? 'up' : 'down';
    };

    const onClick = (column): void => {
        sortedColumn = column;
        onSortColumnChange(sortedColumn);
    };

    const onInputChange = (filterColumn, {target: {value}}): void => {
        let filters = $gesucheFilter;
        filters.set(filterColumn, value.toLowerCase().trim());
        gesucheFilter.set(filters);

        if ($gesucheFilter.get(filterColumn) === '') {
            $gesucheFilter.delete(filterColumn);
        }
    };
</script>

<thead>
<tr>
    {#each Object.entries(GesuchTableColumn) as [key, value] }
        <th>
            <span on:click={() => onClick(value)}>
                {$t('GesuchTableColumn.' + value)}
                {#if getClass(value) === 'up'}
                    <ArrowUpIcon size="1x" />
                {:else if getClass(value) === 'down'}
                    <ArrowDownIcon size="1x"/>
                {/if}
            </span>
            <input class="form-control form-control-sm" value="{$gesucheFilter.get(value) || ''}"
            type="text" placeholder="Filtern" on:input={(event)=>onInputChange(value, event)}/>
        </th>
    {/each}
</tr>
</thead>

<style>

    tr {
        position: sticky;
        top: 0;
        background-color: white;
        white-space: nowrap;
        box-shadow: inset 0 -1px 0 #000000;
    }

    th {
        width: auto;
        border: none;
    }

    span {
        margin-right: 0.5rem;
        cursor: pointer;
        color: #575756;
    }

    span:hover {
        color: #ad2221;
    }
</style>
