<script lang="ts">
  import EditDialog from '../EditDialog/EditDialog.svelte'
  import {fetchGesuche, filterAbgeschlossen} from '../../stores/stores'

  import type {Auftrag, Gesuch} from '../../types'
  import {GesuchTableColumn} from '../../types'
  import {Modal} from 'bootstrap'

  export let gesuche: Gesuch[]

  let modal: Modal | undefined
  let modalElement: HTMLElement

  let auftrag: Auftrag = {
    einkaeufer: '',
    einkaeuferEmail: '',
    eigentumStadt: '',
    eigentumPlz: '',
    eigentumStrasseHausnr: ''

  } as Auftrag

  let gesuch: Gesuch | null = null

  function getTableColumns(gesuch) {
    return Object.entries(GesuchTableColumn).map(([k, v]) => {
      return [k, v.split('.').reduce((o, p) => o ? o[p] : null, gesuch)]
    })
  }

  function rowCallback(g: Gesuch): void {
    gesuch = g
    setTimeout(() => {
      modalElement = document.getElementById('editDialog')
      modal = new Modal(modalElement)
      modal.show()
      modalElement.addEventListener('hidden.bs.modal', () => gesuch = null, {once: true})
    })
  }

  function closeDialog(): void {
    (modal as Modal).hide()
    fetchGesuche()
  }
</script>

<tbody>
{#each gesuche as gesuch}
    {#if gesuch.neu !== 'Abgeschlossen' || (gesuch.neu === 'Abgeschlossen' && !$filterAbgeschlossen)}
        <tr on:click={() => rowCallback(gesuch)} class:marked-row={gesuch.markAsToDo}>
            {#each getTableColumns(gesuch) as [key, value] }
                <td nowrap>
                    {value || value === 0 ? value : ''}
                </td>
            {/each}
        </tr>
    {/if}
{/each}

{#if gesuch}
    <EditDialog on:close={closeDialog} {gesuch}/>
{/if}
</tbody>

<style>
    .marked-row {
        font-weight: bold;
    }

    td {
        cursor: pointer;
    }
</style>
